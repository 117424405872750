.HeroSlider_main {
  margin-top: 4rem;
}
.HeroSlider_inner {
  background-position: inherit;
  /* backdrop-filter: blur(2px); */
  background-size: cover;
  width: 100%;
  height: 85vh;
  display: flex;
  align-items: center;
}
.hero_content_main {
  margin: 0 auto;
  max-width: 1140px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  color: white;
  max-width: 800px;
  letter-spacing: 1px;
  gap: 1rem;
}

.product_heading {
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product_heading h2 {
  font-size: 3rem;
}
.product_title {
  line-height: 1.3;
}

@media screen and (max-width: 900px) {
  .hero_content_main {
    margin: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .product_heading h2 {
    font-size: 2rem;
  }
  .product_title {
    font-size: 20px;
  }
}
@media screen and (max-width: 500px) {
  .HeroSlider_inner{
    height: 60vh;
  }
}
@media screen and (max-width: 400px) {
  .product_heading h2 {
    font-size: 1.5rem;
  }
  .product_title {
    font-size: 18px;
  }
}
.carousel.carousel-slider .control-arrow {
  bottom: 0;
  color: #fff;
  font-size: 26px;
  margin-top: 0;
  padding: 5px;
  top: 0;
}
