.whyusHome_content_main {
  display: grid;
  margin: auto;
  max-width: 1140px;
  gap: 5rem;
  row-gap: 1rem;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 2rem;
}

.whyusHome_card_main {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #b4b4b4;
  max-width: 320px;
  height: 290px;
  background: rgba(0, 0, 0, 0.5);
  padding: 1rem;
  border-radius: 10px;
  justify-content: space-between;
}

.whyusHome_img_inner {
  filter: grayscale(100%) invert(00%) contrast(100%);
  transition: transform 0.5s;
  transition: all 0.4s ease-in-out;
  height: 50px;
}
.whyusHome_img_inner:hover {
  filter: grayscale(0%);
}

@media screen and (max-width: 1140px) {
  .whyusHome_content_main {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 700px) {
  .whyusHome_content_main {
    grid-template-columns: 1fr;
  }
  .whyusHome_content_main {
    gap: 3rem;
  }
}
@media screen and (max-width: 600px) {
  .whyusHome_card_main {
    text-align: center;
    align-items: center;
  }
}
