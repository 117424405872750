.main_flex {
  max-width: 1140px;
  margin: 0 auto;
  padding: 2rem 0;
  padding-bottom: 3rem;
  display: flex;
  flex-wrap: wrap;
}

.wrapper {
  width: 280px !important;
  height: 340px;
  /* width: 300px !important;
  height: 400px; */
  background: white;
  margin: auto;
  position: relative;
  overflow: hidden;
  border-radius: 10px 10px 10px 10px;
  transform: scale(0.95);
  transition: 0.5s, transform 0.5s;
}
.wrapper:hover {
  transform: scale(1);
}
.wrapper .container {
  width: 100%;
  height: 100%;
  padding: 0px;
}
.wrapper .container .top {
  height: 80%;
  width: 100%;
  -webkit-background-size: 100%;
  -moz-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
}
.wrapper .container .bottom {
  width: 200%;
  height: 20%;
  transition: transform 0.5s;
}
.wrapper .container .bottom.clicked {
  transform: translateX(-50%);
}
.wrapper .container .bottom h1 {
  margin: 0;
  padding: 0;
}
.wrapper .container .bottom p {
  margin: 0;
  padding: 0;
}
.wrapper .container .bottom .left {
  height: 100%;
  width: 50%;
  background: #f4f4f4;
  position: relative;
  float: left;
  /* background: #8ac83f; */
  background: #185519;
  display: flex;
  align-items: center;
  font-size: 22px !important;
  text-transform: capitalize;
}
.wrapper .container .bottom .left .details {
  padding: 20px;
  float: left;
  /* width: calc(70% - 40px); */
  color: #fff;
  font-weight: 600;
  /* padding-top: 7.5rem; */
}
.wrapper .container .bottom .left .buy {
  float: right;
  width: calc(30% - 2px);
  height: 100%;
  background: #f1f1f1;
  transition: background 0.5s;
  border-left: solid thin rgba(0, 0, 0, 0.1);
}
.wrapper .container .bottom .left .buy i {
  font-size: 30px;
  padding: 30px;
  color: #254053;
  transition: transform 0.5s;
}
.wrapper .container .bottom .left .buy:hover {
  background: #a6cdde;
}
.wrapper .container .bottom .left .buy:hover i {
  transform: translateY(5px);
  color: #00394b;
}
.wrapper .container .bottom .right {
  width: 50%;
  background: #a6cdde;
  color: white;
  float: right;
  height: 200%;
  overflow: hidden;
}
.wrapper .container .bottom .right .details {
  padding: 20px;
  float: right;
  width: calc(70% - 40px);
}
.wrapper .container .bottom .right .done {
  width: calc(30% - 2px);
  float: left;
  transition: transform 0.5s;
  border-right: solid thin rgba(255, 255, 255, 0.3);
  height: 50%;
}
.wrapper .container .bottom .right .done i {
  font-size: 30px;
  padding: 30px;
  color: white;
}
.wrapper .container .bottom .right .remove {
  width: calc(30% - 1px);
  clear: both;
  border-right: solid thin rgba(255, 255, 255, 0.3);
  height: 50%;
  background: #bc3b59;
  transition: transform 0.5s, background 0.5s;
}
.wrapper .container .bottom .right .remove:hover {
  background: #9b2847;
}
.wrapper .container .bottom .right .remove:hover i {
  transform: translateY(5px);
}
.wrapper .container .bottom .right .remove i {
  transition: transform 0.5s;
  font-size: 30px;
  padding: 30px;
  color: white;
}
.wrapper .container .bottom .right:hover .remove,
.wrapper .container .bottom .right:hover .done {
  transform: translateY(-100%);
}
.wrapper .inside {
  z-index: 9;
  /* background: #8ac83f; */
  background: #185519;
  width: 140px;
  height: 140px;
  position: absolute;
  top: -70px;
  right: -70px;
  border-radius: 0px 0px 200px 200px;
  transition: all 0.5s, border-radius 2s, top 1s;
  overflow: hidden;
}
.wrapper .inside .icon {
  position: absolute;
  right: 85px;
  top: 85px;
  color: white;
  opacity: 1;
}
.wrapper .inside:hover {
  width: 100%;
  right: 0;
  top: 0;
  border-radius: 0;
  height: 80%;
}
.wrapper .inside:hover .icon {
  opacity: 0;
  right: 15px;
  top: 15px;
}
.wrapper .inside:hover .contents {
  opacity: 1;
  transform: scale(1);
  transform: translateY(0);
}
.wrapper .inside .contents {
  padding: 5%;
  opacity: 0;
  transform: scale(0.5);
  transform: translateY(-200%);
  transition: opacity 0.2s, transform 0.8s;
}
.wrapper .inside .contents .pro_card_text {
  text-align: left;
  font-size: 16px;
  width: 100%;
  letter-spacing: 1.5px;
  line-height: 1.3;
}
.wrapper .inside .contents h1,
.wrapper .inside .contents p,
.wrapper .inside .contents table {
  color: white;
}
.wrapper .inside .contents p {
  font-size: 13px;
}

.pro_card_text {
  font-size: 18px;
  margin-bottom: 20px;
  /* padding: 1rem;
  color: #fff;
  font-size: 16px;
  line-height: 1.2; */
}
