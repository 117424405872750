.nav-main-nav-top {
  background: var(--color-green-main);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}
.Navbar_main {
  display: flex;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 6rem;
}
.navbar_links_main {
  display: flex;
  gap: 1.4rem;
  align-items: center;
}

.link_decor_nav {
  text-decoration: none !important;
  transition: all ease-in-out 0.4s;
  cursor: pointer;
  background: linear-gradient(currentColor 0 0) bottom / var(--d, 0) 3px
    no-repeat;
  transition: 0.5s;
  padding: 5px;
}
.link_decor_nav:hover {
  --d: 90%;
  color: white;
}
/*  */
.dropbtn {
  background-color: white !important;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
  background-color: none !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10px;
  top: 25px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 200px;
}

.dropdown-content a:hover {
  background-color: #ddd;
  border-radius: 10px;
}

.dropdown:hover .dropdown-content {
  display: block;
  /* margin-top: 0.2rem; */
}

.nav-btn-icon-part {
  /* background-color: yellow; */
  height: 90px;
  /* width: 75px; */
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
}

.nav_link_h3 {
  font-weight: 600;
  color: var(--color-white);
}

.nav-btn {
  font-size: 30px;
  color: var(--color-white);
  /* color: darkgreen; */
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  padding-top: 30px;
}

.mobile-nav-main {
  padding: 2rem 1rem 1rem 1rem;
}

.mobile-nav-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  list-style: none;
  margin: 0px;
  padding-bottom: 20px;
}

.mobile-nav-link {
  /* color: var(--color-gray); */
  color: var(--color-white);
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}
@media screen and (max-width: 1140px) {
  .Navbar_main {
    padding: 0 3rem;
  }
}

@media screen and (max-width: 678px) {
  .nav-btn-icon-part {
    display: block;
  }
  .navbar_links_main {
    display: none;
  }
}
@media screen and (max-width: 400px) {
  .Navbar_main {
    padding: 0 1.5rem;
  }
}
