.about_main {
  width: 100%;
  height: 100%;
}

.about_img_black_color {
  width: 100%;
  height: 85vh;
  background: rgba(0, 0, 0, 0.5);
}

.nav_link_h3_about {
  color: var(--color-white) !important;
}

.about_img_con {
  display: flex;
  max-width: 1140px;
  margin: 0 auto;
  align-items: center;
  height: 100%;
}

.about_img_con_txt {
  color: var(--color-white);
  font-weight: 600;
  font-size: 64px;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1.2;
}

.about_content_main {
  background: #f6f6f6;
}

.about_content_con {
  max-width: 1140px;
  margin: 0 auto;
  padding: 2rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.about_content_con_para {
  width: 50%;
}
.about_content_text {
  line-height: 1.5;
  letter-spacing: 1px;
}

.our_miss_main {
  width: 100%;
  height: 100%;
  padding: 3rem 0;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
}

.our_miss_con {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2rem;
}

.our_miss_part {
  width: 100%;
  height: 265px;
  padding: 1rem;
  background: rgb(232, 244, 217);
  display: flex;
  align-items: center;
  gap: 20px;
  border-top-left-radius: 126px;
  border-bottom-left-radius: 126px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.our_miss_part2 {
  width: 100%;
  height: 265px;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 20px;
  background: #d1e9f6;
  border-top-right-radius: 126px;
  border-bottom-right-radius: 126px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.our_miss_part_inner_first {
  width: 145px;
  height: 145px;
  border-radius: 50%;
  background-color: var(--color-green-main);
  display: flex;
  align-items: center;
  justify-content: center;
}

.our_miss_part_inner_sec {
  width: 145px;
  height: 145px;
  border-radius: 50%;
  background-color: #1b93d0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.our_miss_part_inner_bgwhite {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  background: var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mission_icon {
  width: 85px;
  height: 85px;
}

.our_miss_text {
  font-size: 16px;
  max-width: 290px;
  line-height: 1.5;
}
/*  */

@media screen and (max-width: 1000px) {
  .our_miss_con {
    flex-wrap: wrap;
  }
  .our_miss_text {
    max-width: 80%;
  }
  .our_miss_part,
  .our_miss_part2 {
    justify-content: space-around;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 840px) {
  .our_miss_text {
    max-width: 60%;
  }
  .about_content_con{
    flex-direction: column-reverse;
    gap: 2rem;
  }
  .about_content_con_para{
    width: 100%;
  }
}
@media screen and (max-width: 450px) {
  .our_miss_part,
  .our_miss_part2 {
    height: 100%;
    border-radius: 0px;
    gap: 10px;
  }
  .our_miss_part {
    border-top-left-radius: 100px;
  }
  .our_miss_text {
    max-width: 100%;
    text-align: center;
  }
  .about_img_con_txt {
    font-size: 35px;
  }
  .about_img_black_color {
    height: 50vh;
  }
  .about_img_con_txt{
    padding-top: 5rem;
  }
  .about_img_black_color{
    background-color: rgba(0, 0, 0, 0.6);
  }
  .our_miss_part_inner_first,.our_miss_part_inner_sec{
    height: 130px;
    width: 130px;
  }
}
