.values_main_top {
  padding: 2rem 0;
  padding-top: 3rem;
  background-color: #f5f5f5;
}
.values_head_main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.values_main {
  display: flex;
  gap: 4rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 3rem;
  padding: 1rem;
  margin: 0 auto;
  max-width: 1140px;
  row-gap: 2rem;
}

.values_boxes {
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding: 2rem;
  transition: 0.5s all ease;
  background: white;
  border-radius: 20px;
  position: relative;box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.values_boxes:hover {
  background: #f5f5f5;
  border-radius: 20px;  
}

.values_boxes .values_img_inner {
  height: 60px;
  transition: filter 0.3s ease;
}

.values_boxes:hover .values_img_inner:hover {
  filter: grayscale(100%);
}

.heading_values {
  font-weight: 800;
}
.values_para {
  font-size: 14px;
  color: var(--color-gray);
  text-align: center;
  word-spacing: 3px;
  line-height: 1.6;
}

@media screen and (max-width: 1120px) {
  .values_boxes {
    max-width: 300px;
  }
}
@media screen and (max-width: 1000px) {
  .values_boxes {
    gap: 1rem;
  }
}
@media screen and (max-width: 700px) {
  .values_main {
    gap: 2rem;
  }
  .values_boxes {
    max-width: 300px;
  }
}
@media screen and (max-width: 600px) {
  .values_boxes {
    align-items: flex-start;
    padding: 1.2rem;
    gap: 0.5rem;
  }
  .values_para {
    text-align: left;
  }
  .values_main {
    gap: 1rem;
  }
  .values_boxes .values_img_inner {
    height: 48px;
  }
}
@media screen and (max-width: 400px) {
  .values_head_main {
    font-size: 14px;
  }
}
