.form_main {
  margin: 0 auto;
  max-width: 1140px;
  padding: 2rem 6rem;
}
.quote_form_main {
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
}
.field_main {
  font-weight: 700;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
}

.form_main_top {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#000),
    color-stop(80%, rgba(0, 0, 0, 0.387))
  );
  color: var(--color-white);
}

.input_transparent,
.textarea_transparent {
  font-weight: 400 !important;
  background: transparent;
  color: var(--color-white);
  border: 1px solid white !important;
}

.PhoneInputInput {
  border: hidden;
  padding: 0.6rem;
  outline: hidden;
  background: transparent;
  color: var(--color-white);
}

.checkbox_inner {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.PhoneInputInput:focus {
  border: none;
  outline: none;
}

.quote_btn_main {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.quote_button {
  padding: 0.7rem 1rem;
  border-radius: 10px;
  border: 2px solid var(--color-green-main);
  color: white;
  cursor: pointer;
  background-color: var(--color-green-main);
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 1px;
}

.quote_button:hover {
  background-color: var(--color-white);
  transition: all 0.6s ease-in-out;
  border: 2px solid var(--color-green-main);
  color: var(--color-green-main);
}

@media screen and (max-width: 600px) {
  .form_main {
    margin: 0 auto;
    max-width: 1140px;
    padding: 2rem 3rem;
  }
  
.form_main_top {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#000),
    color-stop(80%, rgba(0, 0, 0, 0.587))
  );
 }

}
