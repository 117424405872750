.pro_head_main {
  margin-top: 10rem;
  padding: 1.5rem;
  font-size: 2rem;
  font-weight: 800;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  letter-spacing: 1px;
  background: var(--color-green-main);
}

.FruitsVeges_main {
  max-width: 1140px;
  margin: 0 auto;
  background-color: #e8f4d9;
  display: flex;
  justify-content: center;
  padding: 1.6rem;
  margin-bottom: 2rem;
}

.Fruits_main {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.6rem;
  width: 100%;
  justify-items: center;
}

.gallery_item {
  overflow: hidden;
  height: 280px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Fruits_main_img {
  object-fit: cover;
  display: block;
  height: 100%;
  width: 100%;
  transition: transform 0.6s;
}

.gallery_item:hover .Fruits_main_img {
  transform: scale(1.1);
}

.hover_text {
  position: absolute;
  bottom: 10px;
  left: 10px;
  right: 10px;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 8px 12px;
  border-radius: 5px;
  opacity: 1;
  transition: opacity 0.3s ease;
  font-size: 1rem;
  text-align: center;
}

.searchcontainer {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: 1140px;
}

.searchinput {
  padding: 8px;
  font-size: 18px;
  width: 300px;
  margin: 20px;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-weight: 700;
}

@media screen and (max-width: 1140px) {
  .pro_main_para {
    padding: 0 3rem;
  }
  .FruitsVeges_main {
    padding: 1rem 3rem;
  }
}
@media screen and (max-width: 1000px) {
  .Fruits_main {
    grid-template-columns: repeat(2, 2fr);
  }
}
@media screen and (max-width: 768px) {
  .Fruits_main {
    grid-template-columns: repeat(1, 1fr);
  }
  .searchcontainer {
    justify-content: center;
  }
}
@media screen and (max-width: 400px) {
  .pro_main_para {
    padding: 0 1.5rem;
  }
  .searchinput {
    margin: 20px 47px;
  }
}
