.BlanchPeanut_main {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.BlanchPeanut_img_main {
  width: 50%;
}
.BlanchPeanut_table_main {
  width: 50%;
  margin: 0px;
}

@media screen and (max-width: 1220px) {
  .BlanchPeanut_para_main {
    padding: 2rem;
    margin: 0px !important;
    padding-bottom: 0px;
  }
}
@media screen and (max-width: 900px) {
  .BlanchPeanut_main {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .BlanchPeanut_img_main,
  .BlanchPeanut_table_main {
    width: 100%;
  }
}
