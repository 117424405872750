.footer_main1 {
  background: #071c1f;
  background: var(--color-green-main);
  padding: 3rem 2rem 3rem 2rem;
  color: var(--color-white);
}

.footer_main {
  margin: 0 auto;
  max-width: 1140px;
  display: flex;
  gap: 1rem;
}

.links_sec1,
.links_sec2,
.links_sec3 {
  display: flex;
  flex-direction: column;
}

.link_inner {
  color: white;
  line-height: 2.5;
  text-decoration: none;
}

.footer_box1 {
  margin-right: 1.5rem;
}

.footer_box1,
.footer_box2,
.footer_box4 {
  width: 100%;
}

.footer_box3 {
  width: 50%;
}

.headings {
  margin-bottom: 0.5rem;
}

.aboutuspara {
  letter-spacing: 0.3px;
  line-height: 1.6;
}

.rights_reserved {
  color: var(--color-white);
}
.last_sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #16423c;
  padding: 2rem;
}

.footer_link_flex {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 60%;
}

.footer_add_main {
  font-size: 16px;
  line-height: 1.5rem;
  color: var(--color-white);
}

.footer_icon_flex {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--color-green-nav);
}

.footer_link {
  font-size: 16px;
  line-height: 2;
  color: var(--color-white);
}

.link_footer_icon {
  width: 22px;
  height: 22px;
  color: var(--color-white);
}

.Footer_main_social {
  display: flex;
  justify-content: center;
  gap: 3rem;
  font-size: 30px;
  color: var(--color-white);
  flex-wrap: wrap;
}

.Footer_main_facebook_social,
.Footer_main_twitter_social,
.Footer_main_insta_social,
.Footer_main_pinterest_social,
.Footer_main_youtube_social,
.Footer_main_whatsapp_social {
  color: var(--color-white);
  transition: all 0.5s ease-in-out;
}

.Footer_main_facebook_social:hover,
.Footer_main_twitter_social:hover,
.Footer_main_insta_social:hover,
.Footer_main_pinterest_social:hover,
.Footer_main_youtube_social:hover,
.Footer_main_whatsapp_social:hover {
  transform: rotate(360deg);
}

.Footer_main_facebook_social:hover {
  color: dodgerblue;
  /* transform: rotate(360deg); */
}
.Footer_main_twitter_social:hover {
  color: dodgerblue;
  color: #1da1f2;
  /* transform: rotate(360deg); */
}
.Footer_main_insta_social:hover {
  color: deeppink;
}
.Footer_main_pinterest_social:hover {
  color: #c8232c;
}
.Footer_main_youtube_social:hover {
  color: red;
  /* background-color: white; */
}
.Footer_main_whatsapp_social:hover {
  color: rgb(159, 236, 52);
}
/* 
.flinkbtn {
  position: fixed;
  bottom: 30px;
  z-index: 155555555;
  right: 30px;
}

.flinkbtn a {
  background-color: #4dc247 !important;
  color: #fff;
  height: 45px;
  display: flex;
  width: 45px;
  justify-content: center;
  align-items: center;
  font-size: 29px;
  border-radius: 100px;
  padding: 0 0 1px 0;
  cursor: pointer;
} */

.flinkbtn {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 155555555;
}

.flinkbtn a {
  background-color: #4dc247 !important;
  color: #fff;
  height: 45px;
  width: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 29px;
  border-radius: 100px;
  padding: 0 0 1px 0;
  cursor: pointer;
  position: relative;
}

.flinkbtn .qrcode-container {
  position: absolute;
  bottom: 60px; 
  left: 0%;
  transform: translateX(-50%);
  display: none;
  z-index: 155555556;
}

.flinkbtn .qrcode-container img {
  width: 120px; /* Adjust size as needed */
  height: 120px;
  border: 1px solid #ccc;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.flinkbtn a:hover .qrcode-container {
  display: block;
}

@media screen and (max-width: 1100px) {
  /* @media screen and (max-width: 767px) { */
  .footer_main {
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 578px) {
  .last_sec {
    gap: 1rem;
    flex-direction: column;
  }
  .footer_link_flex {
    width: 100%;
  }
}
