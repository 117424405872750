@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,400;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap");
:root {
  --font-base: "Lato", sans-serif;
  --font-pro: "Courier Prime", monospace;
  --color-white: #ffffff;
  --color-black: #0d0202;
  --color-gray: #808080;
  --color-lightgray: lightgray;
  --color-darkgray: #a9a9a9;
  --color-brown: brown;
  --color-red: #e3001b;
  --color-green-nav: #456520;
  /* --color-green-main: #8ac83f; */
  /* --color-green-main: #0C1E1B; */
  --color-green-main: #16423c;
  --color-green-dark: #6da548;
  --color-blue: #162a83;
  --color_blue_shade: #0d4598;
  --color_orange_shade: #f29400;
}
/* https://colorhunt.co/palettes/green */
/* #071C1F */
/* #16423c */
/* #185519 */
/* #6A9C89 */
/* #6A9C89 */
/* #e8f4d9 */
/* #C4DAD2 */
/* #E9EFEC */

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  font-family: var(--font-base);
  initial-scale: 1 !important;
}

a {
  color: black;
  text-decoration: none;
}

.button_light {
  padding: 1rem;
  cursor: pointer;
  color: var(--color-white);
  font-weight: 900;
  border: 2px solid var(--color-white);
  border-radius: 4px;
  padding: 0.7rem 1rem;
  text-transform: uppercase;
  word-spacing: 2px;
  background-color: transparent;
}
.button_light:hover {
  background-color: var(--color-green-main);
  color: var(--color-white);
  transition: all 0.6s ease-in-out;
}
.button_dark {
  padding: 1rem;
  cursor: pointer;
  font-weight: 900;
  border: 2px solid;
  border-radius: 4px;
  text-transform: uppercase;
  background-color: var(--color-green-main);
  color: var(--color-white);
}

.button_dark:hover {
  background-color: transparent;
  color: var(--color-green-main);
  transition: all 0.6s ease-in-out;
}

.heading {
  display: flex;
  letter-spacing: 1px;
  gap: 0.5rem;
  justify-content: center;
  margin: 2rem 0px;
  margin-bottom: 0px;
  color: var(--color-green-dark);
  text-transform: capitalize;
}

.underline {
  width: 10rem;
  height: 0.25rem;
  background: var(--color-green-dark);
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
  margin-top: 0.5rem;
}

.para {
  margin: 0 auto;
  max-width: 1140px;
  margin-top: 3rem;
  margin-bottom: 1rem;
  letter-spacing: 0.5px;
  word-spacing: 0.5px;
   line-height: 1.5;
  letter-spacing: 1px;
}
