.ContactUsHome_main {
  gap: 1.5rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding-top: 3rem; */
  /* margin-top: 5rem; */
  /* margin: 0 auto; */
  /* max-width: 1440px; */
}
.heading_sec_contact_main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contactustitle {
  font-weight: 900;
  font-size: 35px;
}

.input_field {
  padding: 0.6rem;
  border: 2px solid;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 700;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.input_field_message {
  padding: 1rem;
  border: none;
  font-weight: 700;
  font-size: 15px;
  border: 2px solid;
  /* color: var(--color-white); */
  /* background: var(--color-lightgray); */
}

.contactUs_Keep_in_touch_main {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  width: 40%;
}
.contactUs_Keep_in_touch_main p {
  line-height: 1.3;
  letter-spacing: 1px;
}

.form_sec_main_home {
  display: flex;
  gap: 2rem;
  padding-top: 2rem;
  justify-content: center;
}

.form_sec_main_home_inner {
  display: flex;
  gap: 2rem;
  flex-direction: column;
}

.form_sec_main_home_inner_part {
  display: flex;
  gap: 2rem;
}

.map_and_contact_main {
  display: flex;
  justify-content: space-around;
  margin: 2rem;
  gap: 2rem;
  flex-wrap: wrap;
  align-items: center;
}

.socialicons_contact_home_main {
  font-size: 19px;
  display: flex;
  gap: 2rem;
  color: var(--color-gray);
}
.socialicons_contact_home_inner:hover {
  color: var(--color-green-dark);
}

.contactdetails_home_main {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}

.contactdetails_home1 {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
}

.details_p_contact {
  color: var(--color-gray);
}

.ContactUsCards_mapblock {
  max-width: 100%;
}

.map_inner {
  border-radius: 5px;
}

.map_inner:hover {
  filter: grayscale(0%);
}

.sendmessagebtn_home {
  border: 2px solid var(--color-green-dark);
}
.sendmessagebtn_home:hover {
  border: 2px solid var(--color-green-dark);
}

.sendmessagebtn_home2 {
  display: none;
}


/* @media screen and (max-width: 1440px) {
  .ContactUsCards_mapblock {
    padding: 0 4rem;
  }
} */
@media screen and (max-width: 900px) {
  .form_sec_main_home {
    flex-direction: column;
    align-items: center;
    flex-direction: column-reverse;
  }
  .contactUs_Keep_in_touch_main {
  width: 85%;
}

  .sendmessagebtn_home {
    display: none;
  }

  .sendmessagebtn_home2 {
    display: block;
    border: none;
    color: white;
    font-weight: 600;
    background: var(--color-green-dark);
    margin-top: 2rem;
  }
}
@media screen and (max-width: 615px) {
  .contactdetails_home_main {
    align-items: flex-start;
  }
  .map_and_contact_main {
    justify-content: flex-start;
    /* margin: 0px;
    width: 85%; */
  }
  .contactdetails_home1 {
    flex-direction: column;
  }
}
@media screen and (max-width: 500px) {
  .input_field_message {
    width: 100%;
  }
  .map_inner {
    width: 100%;
  }
  .form_sec_main_home_inner{
    padding: 0 1rem;
  }
  .ContactUsCards_mapblock{
    max-width: 100% !important;align-items: center;
        display: flex

  }
}

@media screen and (max-width: 430px) {
  .form_sec_main_home_inner_part {
    flex-direction: column;
  }
  .form_sec_main_home_inner {
    width: 90%;
  }
}
