.RawPeanut_content_main {
  margin: 0 auto;
  max-width: 1140px;
}
.Peanuts_image_slider_inner {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
.Peanut_slider_name {
  padding: 1rem;
  text-align: center;
  letter-spacing: 1px;
}

/* .slick-slide {
  margin: 0 2rem;
} */

table {
  border-collapse: collapse;
  width: 100%;
  margin: 1rem 0 2rem 0;
}
td {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 3px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  padding: 10px 15px;
  background: lightgray;
  font-weight: 600;
}
th {
  border: 1px solid gray;
  padding: 10px 15px;
  background-color: var(--color-green-main);
  color: #fff;
  text-align: justify;
}

@media screen and (max-width: 1220px) {
  .RawPeanut_content_main {
    padding: 2rem;
  }
}
